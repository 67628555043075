import React, { useContext, useEffect, useMemo } from "react";
import { StyledGridRow } from "../../commons/Grid";
import { toast } from "react-toastify";
import { DataContext } from "../../contexts/DataContext";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import useApi from "../../hooks/useApi";

import { 
  StyledRedeemTreeForm, 
  StyledRedeemTreeFormSubmit,
  StyledRedeemTreeSuccessModal,
} from "./style";
import {
  StyledForm,
  StyledFormFieldset,
  StyledFormBlock,
  StyledFormInput,
  StyledFormSubmit,
} from "../../commons/Form";
import Icon, { icons } from "../Icon/Icon";
import { Colors } from "../../commons/Theme";
import { LayoutContext } from "../../contexts/LayoutContext";
import Link from "../Link/Link";
import { Button } from "@3beehivetech/components";

const FormRedeemTree = () => {
  toast.configure();
  const { pageLabels, i18nOasiLink } = useContext(DataContext);
  const { setModalContent } = useContext(LayoutContext);
  const { getRedeemTree } = useApi();
  const giftCodeFromSearchParams = useMemo(() => {
    if (typeof window !== "undefined") { 
      const url = new URL(window.location.href);
      return url.searchParams.get("giftcode");
    }
    return "";
  },[]); 

  const formSchema = z.object({ 
    name: z.string().min(1),
    surname: z.string().min(1),
    email: z.string().email().min(1),
    treeName: z.string().min(1),
    giftCode: z.string().min(1),
  });

  const {
    handleSubmit,
    formState: {
      errors,
    },
    register,
    setFocus,
    reset,
  } = useForm({ resolver: zodResolver(formSchema), defaultValues: { giftCode: giftCodeFromSearchParams } });

  useEffect(() => {
    const keys = Object.keys(errors);
    if (keys.length > 0) {
      const fieldName = keys[0];
      const name = errors[fieldName].ref?.placeholder ?? fieldName;
      setFocus(fieldName);
      toast.error(`${name}: ${errors[fieldName].message}`,{
        containerId: "Alert",
        position: toast.POSITION.BOTTOM_CENTER, 
      });
    }
  }, [setFocus, errors]);

  const trackError = (message) => {
    window.dataLayer.push({
      event: "customError",
      custom: {
        errorSource: "FormRedeemTree",
        errorMessage: `FormRedeemTree: ${message}`,
      },
    });
  };

  return (
    <StyledRedeemTreeForm id="redeemForm">
      <StyledGridRow>
        <StyledForm
          Validate
          onSubmit={handleSubmit((params) => {
            getRedeemTree(params)
              .then((response) => {
                if (response.status === 204) {
                  setModalContent(
                    <StyledRedeemTreeSuccessModal>
                      <h1>{pageLabels.modal.title}</h1>
                      <p>{pageLabels.modal.paragraph}</p>
                      <Link
                        to={i18nOasiLink("/me")}>
                        <Button variant="orange">
                          {pageLabels.modal.button}
                        </Button>
                      </Link>
                    </StyledRedeemTreeSuccessModal>,
                  );
                  reset();
                } else {
                  toast.error(pageLabels.errorMessage, {
                    containerId: "Alert",
                    position: toast.POSITION.BOTTOM_CENTER,
                  });
                  trackError(JSON.stringify(params));
                }
              })
              .catch(function () {
                toast.error(pageLabels.errorMessage, {
                  containerId: "Alert",
                  position: toast.POSITION.BOTTOM_CENTER,
                });
                trackError(JSON.stringify(params));
              });

            return false;
          })}
        >
          <StyledFormFieldset>
            <StyledFormBlock>
              <StyledFormInput
                id="form-name"
                type="text"
                placeholder={pageLabels.form.name}
                required
                {...register("name")}
              />
            </StyledFormBlock>
            <StyledFormBlock>
              <StyledFormInput
                id="form-surname"
                type="text"
                placeholder={pageLabels.form.surname}
                required
                {...register("surname")}
              />
            </StyledFormBlock>
            <StyledFormBlock>
              <StyledFormInput
                id="form-email"
                type="email"
                required
                placeholder={pageLabels.form.email}
                {...register("email")}
              />
            </StyledFormBlock>
            <StyledFormBlock>
              <StyledFormInput
                id="form-treeName"
                type="tel"
                required
                placeholder={pageLabels.form.treeName}
                {...register("treeName")}
              />
            </StyledFormBlock>
          </StyledFormFieldset>
          <StyledFormFieldset>
            <StyledFormBlock>
              <StyledFormInput
                id="form-giftCode"
                type="text"
                required
                placeholder={pageLabels.form.giftCode}
                {...register("giftCode")}
              />
            </StyledFormBlock>
          </StyledFormFieldset>
          <StyledRedeemTreeFormSubmit>
            <StyledFormSubmit type="submit">
              {pageLabels.form.redeem}
              <Icon icon={icons.arrowRight} color={Colors.white} />
            </StyledFormSubmit>
          </StyledRedeemTreeFormSubmit>
        </StyledForm>
      </StyledGridRow>
    </StyledRedeemTreeForm>
  );
};

export default FormRedeemTree;
